<template>
  <v-container>
    <v-row class="fill-height">
      <v-col md="12" class="pt-5 px-6">
        <v-card>
          <v-card-title>
            <h3>Cadastro de Serviço</h3>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="formService"
              v-model="formIsValid"
              v-on:submit.prevent="serviceSubmit(service.id)"
            >
              <v-row>
                <v-col md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col xl="3" lg="4" md="4" sm="6">
                  <v-text-field
                    v-model="service.name"
                    label="Nome"
                    placeholder="Nome do serviço"
                    :rules="textRules"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col xl="5" lg="6" md="6" sm="6">
                  <v-text-field
                    label="Descrição"
                    v-model="service.desc"
                    placeholder="Descrição do serviço"
                    :rules="textRules"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col xl="2" lg="3" md="3" sm="6">
                  <v-text-field
                    v-model.number="service.value"
                    type="number"
                    label="Valor"
                    v-mask="['#.##', '##.##', '###.##', '####.##']"
                    masked="true"
                    placeholder="Valor do serviço."
                    :rules="numberRules"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col xl="4" lg="4" md="4" sm="6">
                  <v-select
                    v-model="service.categoryID"
                    :items="categories"
                    item-text="desc"
                    item-value="id"
                    label="Categoria"
                    placeholder="Escolha a categoria do serviço"
                    :rules="textRules"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    color="error"
                    v-if="service.id"
                    class="mr-4"
                    @click="reset"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-btn color="error" v-else class="mr-4" @click="reset">
                    <v-icon>mdi-close</v-icon>
                    Limpar
                  </v-btn>
                  <v-btn type="submit" color="success">
                    <v-icon left v-if="service.id">mdi-account-edit</v-icon>
                    <v-icon left v-else>mdi-plus</v-icon>
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import {
  CATEGORIES_GET,
  SERVICE_CREATE,
  SERVICE_EDIT,
  SERVICE_GET
} from "@/store/actions/service.type";
import {
  SERVICE_RESET,
  SERVICE_RESET_STATE
} from "@/store/mutations/service.type";

export default {
  name: "CadastroServico",
  directives: { mask },
  props: {
    id: {
      default: 0,
      type: Number,
      required: false
    }
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(SERVICE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(SERVICE_RESET_STATE);
    if (to.params.id !== undefined)
      await store.dispatch(SERVICE_GET, to.params.id);
    await store.dispatch(CATEGORIES_GET);

    return next();
  },
  data: () => ({
    selectMaterialOK: "",
    formIsValid: false,
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      }
    ],
    numberRules: [
      v => {
        // if (!v.trim()) return true;
        if (typeof v !== "undefined" && v !== null) {
          if (!isNaN(v) && v >= 0.0) return true;
        }
        return "Informe um valor válido";
      }
    ],
    telRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
            return pattern.test(value) || "Informe um telefone válido";
          }
        }
        return false;
      }
    ]
  }),
  methods: {
    passwordRule(pass) {
      if (!pass && this.id != 0) return true;
      return !!pass || "Este campo é obrigatório";
    },
    reset() {
      if (this.$route.params.id > 0) {
        this.$router.go(-1);
      } else {
        this.$refs.formService.resetValidation();

        store.commit(SERVICE_RESET);
      }
    },
    serviceSubmit(id) {
      if (this.$refs.formService.validate()) {
        console.log(this.user, id);

        //this.subject.image = this.subject.image

        let action = id ? SERVICE_EDIT : SERVICE_CREATE;
        this.inProgress = true;
        // this.bloqueiaEnvio = true;
        this.$store
          .dispatch(action)
          .then(() => {
            this.inProgress = false;
            this.bloqueiaEnvio = false;
            // this.$router.push({
            //   name: "users",
            //   params: { id: data.id }
            // });
            this.$refs.formService.resetValidation();
            store.commit(SERVICE_RESET);
            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";
            // if (this.user.id > 0) {
            this.$router.push({ name: "services" });
            // }
          })
          .catch(({ response }) => {
            this.inProgress = false;
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar usuário.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text = response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    }
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["service", "categories", "snackbar"])
  },
  watch: {}
};
</script>
